import React from "react"

import { Link } from "gatsby"

import Layoutcs from "../../components/layoutcs/layoutcs"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faRocket, faHeart, faSmile, faUserAstronaut, faCodeBranch, faFighterJet } from '@fortawesome/free-solid-svg-icons'

import Techstack from "../../components/techstack/techstack"

import "../index.scss"
import "../careers.scss"
import "../contact.scss"

import VideoBg from "../../videos/ftrs-about-us.mp4"

const Careers = () => (

    <Layoutcs>
        <SEO title="Kariéra" description="Rosteme a rádi v týmu přivítáme nové hvězdy. Pojďme se pobavit o možnostech spolupráce :)" />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content pb-4">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Přidej se k Future Rockstars</h1>

            <Row>

                <Col xs="12" lg="7" className="ftrs-career pb-3">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-4 mb-5"><span role="img" aria-label="flash">⚡</span> PHP/Nette developer <span role="img" aria-label="rocket">🚀</span><span className="ftrs-career__hot">HOT</span></h2>
                    
                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3"><span role="img" aria-label="info">ℹ️</span> Krátce o nás:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Jsme tým developerů, který dlouhodobě vyvíjí vlastní <Link to="../reference">webové a&nbsp;mobilní aplikace</Link>.</p>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Naše aplikace využívají přední společnosti zejména z&nbsp;odvětví automotive, bankovnictví, telekomunikace, pojišťovnictví (např.&nbsp;Vodafone, T-Mobile, O2, Airbank, Česká&nbsp;spořitelna, Uniqa, Toyota, Harley-Davidson a&nbsp;mnoho dalších).</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="superman">🦸‍♂️</span> Pomoz nám rozvíjet náš tým:</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Na tuto pozici hledáme spíše úroveň medior/senior. Součástí práce na&nbsp;aplikacích je pomoc juniornějším kolegům (například code review, vzájemné školení).</p>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="cleverman">🤓</span> Už teď by sis měl tykat&nbsp;s:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>PHP</li>
                        <li>Nette</li>
                        <li>Mysql</li>
                        <li>Git</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="rocket">🚀</span> Budeme nadšeni, pokud budeš mít zkušenosti&nbsp;s:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>Docker</li>
                        <li>Elasticsearch</li>
                        <li>DevOps (AWS, Kubernetes)</li>
                        <li>Angular</li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="sparkling">✨</span> Čeká tě tu:</h3>
                    <ul data-sal="fade" data-sal-duration="1000" className="text-basic">
                        <li>práce na zajímavých projektech pro&nbsp;ještě zajímavější klienty</li>
                        <li>pravidelné vzdělávací akce</li>
                        <li>příjemná atmosféra zahrnující kancelář s&nbsp;terasou a&nbsp;klimatizací</li>
                        <li>možnost home office / full remote</li>
                        <li>platové ohodnocení dle&nbsp;tvých zkušeností</li>
                        <li>výhody stabilní a&nbsp;fungující firmy (firemní akce, offsite, školení, benefity&nbsp;apod.)</li>
                        <li>vlastní firemní jachta na&nbsp;Slapech k&nbsp;dispozici týmu <span role="img" aria-label="boat">⛵</span></li>
                    </ul>

                    <h3 data-sal="fade" data-sal-duration="1000" className="ftrs-h3 mt-4"><span role="img" aria-label="thinking">🤔</span> Jaké aplikace budeš u nás vyvíjet?</h3>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Jsou to nejlepší appky na&nbsp;trhu v&nbsp;oblasti Marketingového výzkumu trhu. Spravují projekty typu Mystery shopping, Audit, řeší nábor spolupracovníků, odměňování, sběr a&nbsp;kontrolu dat a&nbsp;reporting klientům. Přes API komunikují s&nbsp;mobilními appkami. Momentálně nás mimo jiné čeká velký samostatný modul "odměny".</p>
                </Col>

                <Col xs="12" lg="5" className="mt-3 pb-3">
                    <div className="ftrs-career__sticky">
                        <blockquote data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers">
                            Do našeho týmu vždy rádi přivítáme nové Rockstars!<br />Pošli své CV Jardovi <span role="img" aria-label="pointing down">👇</span>
                        </blockquote>
                        <div data-sal="fade" data-sal-duration="1000" className="ftrs-contact__box ftrs-contact__box--careers2">
							<p className="ftrs-contact__name">
                                Jaroslav Suchý
                            </p>
                            <p className="ftrs-contact__email">
                                <a href="mailto:jarda@futurerockstars.cz"><FontAwesomeIcon icon={faEnvelope} /> jarda@futurerockstars.cz</a><br />
                            </p>
                            <p className="ftrs-contact__phone">
                                <a href="tel:+420721765529"><FontAwesomeIcon icon={faPhone} /> +420 721 765 529</a>
                            </p>
                        </div>
                    </div>
                </Col>

            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h2 mt-5 mb-5">Proč Future Rockstars?</p>

            <Row>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faRocket} className="ftrs-career__icon ftrs-career__icon--color1" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Vývoj</h3>
                            <p className="text-basic">Rádi se vzděláváme a&nbsp;posouváme naše limity zkoumáním a&nbsp;používáním nových technologií.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faHeart} className="ftrs-career__icon ftrs-career__icon--color2" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Flexibilita</h3>
                            <p className="text-basic">V týmu naší velikosti se dají vztahy a&nbsp;procesy přizpůsobit téměř jakýmkoliv potřebám.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faFighterJet} className="ftrs-career__icon ftrs-career__icon--color3" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Trakce a úspěchy</h3>
                            <p className="text-basic">Naše produkty dodáváme společnostem jako je Audi, Toyota, T-Mobile, Airbank a&nbsp;spousta dalších.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faSmile} className="ftrs-career__icon ftrs-career__icon--color4" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Máš vliv</h3>
                            <p className="text-basic">Okolo 100k uživatelů měsíčně interaguje s&nbsp;našimi produkty.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faUserAstronaut} className="ftrs-career__icon ftrs-career__icon--color5" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Silné zázemí</h3>
                            <p className="text-basic">Future Rockstars je člen silného holdingu s&nbsp;15+ lety zkušeností v&nbsp;monitoringu a&nbsp;analýze trhu a&nbsp;zákaznické zkušenosti.</p>
                        </div>
                    </div>
                </Col>

                <Col xs="12" lg="4" className="ftrs-career pb-4">
                    <div className="ftrs-career__list-item" data-sal="fade" data-sal-duration="1000">
                        <div className="ftrs-career__icon-container">
                            <FontAwesomeIcon icon={faCodeBranch} className="ftrs-career__icon ftrs-career__icon--color6" />
                        </div>
                        <div className="ftrs-career_text">
                            <h3 className="ftrs-h3">Dělíme se o znalosti</h3>
                            <p className="text-basic">Rádi sdílíme znalosti, takže se účastníme webinářů a&nbsp;zveřejňujeme kód na&nbsp;githubu pro&nbsp;open source komunitu. Rovněž jsme hrdí <a href="https://nette.org/cs/donate" target="_blank" rel="noreferrer">PHP Nette bronze partneři</a>.</p>
                        </div>
                    </div>
                </Col>

            </Row>

        </Container>

        <Techstack lang='cs'></Techstack>

    </Layoutcs>

)

export default Careers